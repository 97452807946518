import axios from "axios";
import { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import toast, { Toaster } from "react-hot-toast";

const Modal = ({ toggleModal, propertyData, listing }) => {
  const [email, setEmail] = useState("");
  const [statusMsg, setStatusMsg] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);

  function ValidateEmail(mail) {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    setStatusMsg("You have entered an invalid email address!");
    return false;
  }

  const sendEmail = async () => {
    if (ValidateEmail(email)) {
      setSendingEmail(true);
      try {
        let body = {
          "property-id": propertyData?.["property-id"],
          email: email,
          "listing-overview": listing,
        };
        let res = await axios.post(
          "https://api.honely.com/dev/listing-email-report",
          body
        );

        setStatusMsg("");
        setSendingEmail(false);
        toast.success("Email successfully sent");
        toggleModal(false);
      } catch (error) {
        console.log("send email error", error);
        setSendingEmail(false);
        setStatusMsg("Cannot generate report for this property");
        return;
      }
    }
  };
  return (
    <div className="relative h-full w-full max-w-[600px] max-h-[200px] bg-white rounded-md shadow-md p-4 flex flex-col justify-around items-center">
      <p
        className="absolute top-0 right-3 p-2 text-2xl cursor-pointer text-gray-700"
        onClick={() => toggleModal(false)}
      >
        x
      </p>
      <p className="text-2xl text-center">Enter your email below</p>
      <input
        type="email"
        required
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        placeholder="email@email.com"
        onChange={(e) => setEmail(e.target.value)}
      ></input>
      {!sendingEmail ? (
        <div
          className="rounded-full h-10 text-center w-48 bg-[#24CB43] text-white flex items-center justify-center cursor-pointer"
          onClick={() => sendEmail()}
        >
          Send Report
        </div>
      ) : (
        <svg
          aria-hidden="true"
          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      )}
      {statusMsg.length > 1 && (
        <p className="text-1xl text-center text-[#8b8b8b]">{statusMsg}</p>
      )}
    </div>
  );
};

function App() {
  const [results, setResults] = useState([]);
  const [listing, setListing] = useState("");
  const [tempAddressDate, setTepmAddressData] = useState("");
  const [address, setAddress] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);
  useEffect(() => {
    let timer = null;
    if (searchQuery) {
      setResults([]);
      if (searchQuery.length > 5) {
        timer = setTimeout(() => {
          callSearchAPI(searchQuery);
        }, 500);
      }
    }
    return () => clearTimeout(timer);
  }, [searchQuery]);

  const callSearchAPI = async (userInput) => {
    let res = await axios.get(
      `https://api.honely.com/searches/suggestions?address=${userInput}`
    );
    setResults(res?.data?.suggestions);
  };

  const showErrorToast = (msg) => toast.error(msg);

  const getListingData = async (listingData, regenerate) => {
    setLoading(true);
    setAddress(listingData.address);
    setPropertyData(listingData);
    try {
      const { data } = await axios.post(
        `https://api.honely.com/dev/ai-listing`,
        {
          "property-id": listingData["property-id"],
          regenerate: regenerate ? true : false,
        }
      );

      if (data.data?.tracking_id) {
        // Check tracking ID for fetching listing overview
        let intervalCounter = 0;

        let intervalId = setInterval(async () => {
          // TimeOut check
          if (intervalCounter >= 30) {
            clearInterval(intervalId);
            setLoading(false);
            showErrorToast(
              "Cannot generate a listing at this time. Please try a bit later."
            );
            return;
          }

          intervalCounter += 1;

          const trackingResponse = await axios.get(
            `https://api.honely.com/dev/ai-listing/tracking?tracking_id=${data.data.tracking_id}`
          );

          if (
            trackingResponse.status === 200 &&
            trackingResponse.data.data.status === "SUCCESS"
          ) {
            setListing(trackingResponse.data.data.listing_overview);
            setLoading(false);
            clearInterval(intervalId);
          } else if (
            trackingResponse.status === 200 &&
            trackingResponse.data.data.status === "FAILED"
          ) {
            clearInterval(intervalId);
            setLoading(false);
            showErrorToast(
              "Cannot generate a listing at this time. Please try a bit later."
            );
            return;
          }
        }, 1000);
      } else {
        // Show listing overview if available
        setListing(data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("[API ERROR]");
      showErrorToast(
        "Cannot create listing at this time, please try again later."
      );
      setLoading(false);
      return;
    }
  };

  return (
    <div className="relative">
      <div>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>
      {!listing ? (
        <div className="min-h-[100vh] h-full w-full flex flex-col justify-center items-center bg-slate-100">
          {!loading ? (
            <>
              <h1 className="text-3xl mb-2">Honely AI Search</h1>
              <p className="text-l mb-3 text-center">
                Search for a property and generate a listing
              </p>

              <div className="h-10 w-3/4 max-w-[700px] shadow-md rounded-full bg-white overflow-hidden px-4 py-2">
                <input
                  placeholder="Enter address (e.g. 123 Main Street...)"
                  className="w-full h-full focus:outline-none"
                  onChange={(e) => setSearchQuery(e.target.value)}
                ></input>
              </div>
              <div className="relative w-3/4 max-w-[680px]">
                {results.length > 0 && (
                  <div className="absolute top-0 left-0 w-full mt-2 bg-white overflow-hidden p-4 rounded-lg shadow-md">
                    {results.map((listing, i) => {
                      return (
                        <div
                          className="flex w-full min-h-10 py-2 px-2 items-center border-b border-b-gray-100 cursor-pointer hover:bg-slate-100"
                          key={i}
                          onClick={() => {
                            setTepmAddressData(listing);
                            getListingData(listing, false);
                          }}
                        >
                          <p className="text-sm lg:text-base">
                            {listing?.address}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div role="status">
              <div className="text-center">
                <TypeAnimation
                  sequence={[
                    "Processing your request",
                    500,
                    "Processing your request .",
                    500,
                    "Processing your request . .",
                    500,
                    "Processing your request . . .",
                    1500,
                    "Generating your unique listing",
                    500,
                    "Generating your unique listing .",
                    500,
                    "Generating your unique listing . .",
                    500,
                    "Generating your unique listing . . .",
                    1500,
                    "Your listing is almost ready",
                    500,
                    "Your listing is almost ready .",
                    500,
                    "Your listing is almost ready . .",
                    500,
                    "Your listing is almost ready . . .",
                    1500,
                  ]}
                  speed={50} // Custom Speed from 1-99 - Default Speed: 40
                  style={{ fontSize: "2em" }}
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                  omitDeletionAnimation={true}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="min-h-[100vh] h-full w-full flex flex-col justify-start items-center bg-slate-100">
          <div className="w-full max-w-5xl p-10 flex flex-col items-center">
            <div className="flex w-full justify-between">
              <div
                onClick={() => {
                  setListing("");
                  setResults([]);
                }}
                className="flex items-center py-2 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="mr-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
                Back
              </div>
              <div
                onClick={() => {
                  setListing("");
                  getListingData(tempAddressDate, true);
                }}
                className="flex items-center py-2 cursor-pointer"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7087_22376)">
                    <path
                      d="M2.61639 3.96268C3.78933 2.28896 5.73193 1.25082 7.81026 1.25082C11.3018 1.25082 14.1425 4.09143 14.1425 7.58306H15.3933C15.3933 3.40173 11.9916 0 7.81026 0C6.34938 0 4.93083 0.416482 3.70794 1.20446C2.9331 1.7037 2.26086 2.3362 1.71784 3.06839L0.0859375 1.44432V6.17097H4.8353L2.61639 3.96268Z"
                      fill="black"
                    />
                    <path
                      d="M15.9089 9.83002H11.1596L13.3785 12.0383C12.2056 13.712 10.2629 14.7502 8.18462 14.7502C4.693 14.7502 1.85238 11.9096 1.85238 8.41797H0.601562C0.601562 12.5993 4.00329 16.001 8.18462 16.001C9.6455 16.001 11.0641 15.5845 12.2869 14.7966C13.0618 14.2973 13.734 13.6648 14.277 12.9326L15.9089 14.5567V9.83002H15.9089Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7087_22376">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Regenerate
              </div>
            </div>
            <div className="bg-white rounded-lg mt-10 w-full min-h-[200px] p-4">
              <p className="text-2xl mb-10">Listing for {address}</p>
              <p className="leading-8 font-light">{listing}</p>
            </div>

            <div
              className="flex bg-white rounded-full items-center justify-center h-12 px-4 w-full max-w-[375px] mt-10 cursor-pointer"
              onClick={() => setShowEmailModal(true)}
            >
              <div className="h-10 w-10 mr-4">
                <svg className="svg-icon" viewBox="0 0 20 20">
                  <path d="M17.388,4.751H2.613c-0.213,0-0.389,0.175-0.389,0.389v9.72c0,0.216,0.175,0.389,0.389,0.389h14.775c0.214,0,0.389-0.173,0.389-0.389v-9.72C17.776,4.926,17.602,4.751,17.388,4.751 M16.448,5.53L10,11.984L3.552,5.53H16.448zM3.002,6.081l3.921,3.925l-3.921,3.925V6.081z M3.56,14.471l3.914-3.916l2.253,2.253c0.153,0.153,0.395,0.153,0.548,0l2.253-2.253l3.913,3.916H3.56z M16.999,13.931l-3.921-3.925l3.921-3.925V13.931z"></path>
                </svg>
              </div>
              <p>Get your comprehensive listing report</p>
            </div>
          </div>
        </div>
      )}
      {showEmailModal && (
        <div className="absolute top-0 left-0 h-full w-full bg-slate-500 bg-opacity-60 flex justify-center items-center p-10">
          <Modal
            propertyData={propertyData}
            toggleModal={setShowEmailModal}
            listing={listing}
          />
        </div>
      )}
    </div>
  );
}

export default App;
